<!-- 可视化界面——地图 -->
<template>
  <div id="vMap">
    <baidu-map class="map" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" @ready="mapReadyHandler" @mousemove="syncPolyline" @click="paintPolyline" @rightclick="newPolyline">
      <!-- marker -->
      <bm-marker v-for="item of dataArr" :key="item.uid" :position="{lng: item.lng, lat: item.lat}" @click="markerClick(item)">
        <!-- animation="BMAP_ANIMATION_BOUNCE" -->
        <bm-info-window :show="item.infoWindow.show" @close="markerClick(item)">
          <template>
            <div class="info-window" style="width: 100px; height: 70px;">
              <div class="left">
                <span>{{item.deviceName}}</span>
                <span>水位值：{{item.f}}</span>
                <span v-if="item.rainFall">雨量：{{item.rainFall}}</span>
                <span>信号：{{item.s}}</span>
              </div>
              <!-- <div class="right"> -->
              <!-- <img width="100%" height="100%" src="../assets/test1.jpg" /> -->
              <!-- <video muted controls autoplay="autoplay" loop="loop"
									style="width: 100%; height: 100%;">
									<source src="../assets/test.mp4">
								</video> -->
              <!-- <video id="player" poster="http://open.ys7.com/asdf.jpg" muted autoplay controls playsInline webkit-playsinline style="width: 100%; height: auto;" :src="videoUrl">
                  <source :src="videoUrl" type="application/x-mpegURL">
                </video> -->
              <!-- </div> -->
            </div>
          </template>
        </bm-info-window>
      </bm-marker>
      <bm-control>
        <button @click="toggle('polyline')">{{ polyline.editing ? '保存绘画' : '开始绘画' }}</button>
        <button @click="clearPolyline">清除绘画</button>
        <button @click="openscreen">{{is_screenMap?"返回":"全屏"}}</button>
      </bm-control>
      <bm-polyline :path="path" v-for="path of polyline.paths" :key="path.index"></bm-polyline>
    </baidu-map>
    <!-- 设备统计 -->
    <div class="count">
      <!--
			<div class="item">
				<span>监测点个数</span>
				<span>24</span>
			</div>
				-->
      <div class="item" v-for="item of countItem" :key="item.title">
        <span>{{item.title}}</span>
        <span>{{item.value}}</span>
      </div>
    </div>
    <!-- 图例 -->

    <div class="legend">
      <p>图例</p>
      <div class="item">
        <span></span>
        <span>可能发生</span>
      </div>
      <div class="item">
        <span></span>
        <span>可能性较大</span>
      </div>
      <div class="item">
        <span></span>
        <span>可能性大</span>
      </div>
      <div class="item">
        <span></span>
        <span>可能性很大</span>
      </div>
    </div>

    <!-- 设备详情 -->
    <!-- <div class="marker-info">
			<p class="info-title">设备信息</p>
			<p><span>●</span>设备名称：{{deviceInfo.deviceName}}</p>
			<p><span>●</span>设备UID：{{deviceInfo.deviceUid}}</p>
			<p><span>●</span>设备状态：在线</p>
			<p><span>●</span>更新时间：2021-03-22 11:02:01</p>
			<p><span>●</span>设备坐标：{{deviceInfo.lng}}，{{deviceInfo.lat}}</p>
		</div> -->
    <!-- 视频 -->
    <!-- <div class="dialog" style="display: none;">
			<Video></Video>
		</div> -->
  </div>
</template>

<script>
import MapTheme from '../common/map_theme.js';
import Video from './Video.vue';
import ezuikit from '../common/hk1.3/1.3/ezuikit.js';

export default {
  data() {
    return {
      center: {
        lng: 0,
        lat: 0
      },
      infoWindow: {
        width: 800,
        height: 500
      },
      zoom: 12,
      dataArr: [],
      deviceDataArr: [],
      deviceInfo: {},

      countItem: [
        /*
        {
        title: '报警点个数',
        value: '11'
      }, {
        title: '可能发生',
        value: '6'
      }, {
        title: '可能性较大',
        value: '2'
      }, {
        title: '可能性大',
        value: '2'
      }, {
        title: '可能性很大',
        value: '1'
      }
      */
      ],
      videoData: {
        url: require('../assets/test.mp4'),
        id: ''
      },

      videoUrl: '',
      polyline: {       //绘制
        editing: false,
        paths: []
      },
      mapsource: '',//地图数据源
      is_screenMap: false,
      // selectDevice: ""
    }
  },
  props: {
    deviceItems: {
      type: Array
    }, //设备数据
    mapData: {},
  },
  watch: {
    deviceItems: function () {  //监听是否有数据更新
      // console.log("地图接收的数据：", this.deviceItems);
      this.gpsDataRequest(this.deviceItems)
    },
    "$store.state.settingStore.fullscreen": {  //监听是否全屏化
      handler: function () {
        let isFullScreen = this.$store.state.settingStore.fullscreen  //获取全局存储
        // console.log("11111isFullScreen", isFullScreen);
        this.is_screenMap = isFullScreen
      }
    },
  },
  components: {
    Video
  },
  created() {
    if (JSON.parse(localStorage.getItem('polylinePaths'))) {	//读取本地绘画数据
      this.polyline.paths = JSON.parse(localStorage.getItem('polylinePaths'))
    }

  },
  methods: {
    reload(reloadsource) {
      this.dataArr = reloadsource
    },
    mapReadyHandler({ BMap, map }) {
      // map.setMapStyle({
      // 	styleJson: MapTheme.getMapTheme()
      // });
      this.dataArr = this.mapsource
      var view = map.getViewport(eval(this.dataArr));
      // console.log("mapdata=", this.mapData)

      this.zoom = this.mapData.zoom;//view.zoom;
      // console.log("view.center=", this.dataArr)

      this.center = {
        // 大亚湾
        lng: this.mapData.center.lng,
        lat: this.mapData.center.lat,
      };//view.center;
    },
    //地图标记点击
    markerClick(item) {
      // this.deviceInfo = item;
      let { deviceUid } = item
      let index = this.dataArr.findIndex(val => { return val.deviceUid === deviceUid })
      this.$set(this.dataArr[index].infoWindow, 'show', !this.dataArr[index].infoWindow.show)
      // console.log(111, this.dataArr, item.infoWindow.show);
    },
    toggle(name) {
      this[name].editing = !this[name].editing
      if (!this.polyline.editing) {		//本地存储
        localStorage.setItem('polylinePaths', JSON.stringify(this.polyline.paths))
      }
    },
    clearPolyline() {	//清除历史绘画
      this.polyline.paths = [];
      this.polyline.editing = false
      localStorage.removeItem('polylinePaths')
    },
    syncPolyline(e) {
      if (!this.polyline.editing) {
        return
      }
      const { paths } = this.polyline
      if (!paths.length) {
        return
      }
      const path = paths[paths.length - 1]
      if (!path.length) {
        return
      }
      if (path.length === 1) {
        path.push(e.point)
      }
      this.$set(path, path.length - 1, e.point)
    },
    newPolyline(e) {
      if (!this.polyline.editing) {
        return
      }
      const { paths } = this.polyline
      if (!paths.length) {
        paths.push([])
      }
      const path = paths[paths.length - 1]
      path.pop()
      if (path.length) {
        paths.push([])
      }
    },
    paintPolyline(e) {
      if (!this.polyline.editing) {
        return
      }
      const { paths } = this.polyline
      !paths.length && paths.push([])
      paths[paths.length - 1].push(e.point)
      console.log(this.polyline.paths)
    },
    //请求GPS数据
    gpsDataRequest(devices) {
      var uids = devices.concat().map((e) => { return e.uid; }).join(",");
      // console.log('gps_uid', uids);
      let json = {
        "cmd": "dataStore",
        "id": uids,
        "tag": "gps"
      }
      this.ws.send(json);
      // this.resGpsData()
    },
    //接收GPS数据
    resGpsData() {
      // gps地图坐标
      this.ws.addCallback('dataStoreAck', (res) => {
        // console.log(999, res);
        if (res.items.length == 0) {
          this.mapsource = [];
        } else {
          res.items.map((ele, index) => {
            this.deviceItems.map((row, ix) => {
              //判断是否是当前显示的设备历史数据
              if (row.uid == ele.id) {
                row.location = ele.data.location || '';
              }
            });
          });
          this.mapsource = this.deviceItems.concat().map((ele, eleix) => {
            var location = ele.location.split(",");
            var show = false;
            if (location != '') {
              show = false;
            }
            return {
              lng: location == '' ? '' : location[0],
              lat: location == '' ? '' : location[1],
              deviceName: ele.title,
              deviceUid: ele.uid,
              icon: this.getIcon(eleix),
              infoWindow: {
                show: show
              },
              f: ele.f,//水位
              rainFall: ele.rainFall,//雨量
              s: ele.s,//信号
            }
          }).filter((ele) => { return ele.lat != '' });
        }
        // console.log("地图坐标111:", this.mapsource);
        this.reload(this.mapsource)

      });
    },
    getIcon(i) {
      var icon = require('../assets/marker.png');
      if (i >= 0 && i <= 5) {
        icon = require('../assets/marker.png');
      } else if (i > 5 && i <= 7) {
        icon = require('../assets/marker.png');
      } else if (i > 15 && i <= 18) {
        icon = require('../assets/marker.png');
      } else if (i > 18 && 1 <= 20) {
        icon = require('../assets/marker.png');
      } else {
        icon = require('../assets/marker.png');
      }
      return icon;
    },
    //点击列表地图跳转
    setMapDevice(uid) {
      let index = this.dataArr.findIndex(val => { return val.deviceUid === uid })
      //有地图数据
      if (index != -1 && this.dataArr[index].lat != "") {
        this.center.lng = this.dataArr[index].lng
        this.center.lat = this.dataArr[index].lat
        this.$set(this.dataArr[index].infoWindow, 'show', true) //显示;
      } else {
        this.$message({
          message: '当前设备没有位置信息',
          duration: 2000
        });

      }

    },
    //地图全屏
    openscreen() {
      if (this.is_screenMap == false) {
        this.$router.push({ path: '/dayawan/screenMap' })
      } else {
        this.$emit('closefullmap')
      }
    }
  },
  mounted() {
    this.resGpsData()
    // let logo = this.common.cGetLogo();
    // this.videoUrl = logo.wallVideoUrl;

    // this.videoUrl = "https://hls01open.ys7.com/openlive/be491910110e4a2091c5920e1748e029.hd.m3u8";
  }
}
</script>

<style lang="scss">
#vMap {
  font-size: 12px;
  height: 100%;
  .map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  // 设备统计
  .count {
    position: absolute;
    top: 10px;
    left: 10px;

    $colorlist: #2746a2, #b4002d, #fffc47, #fc6c22, #fc0011, #b5005e;

    @for $i from 1 to 7 {
      .item:nth-child(#{$i}) {
        width: 180px;
        padding: 16px;
        padding-right: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #071124;
        box-shadow: inset 0 0 8px 0 nth($colorlist, $i);
        margin-bottom: 10px;
        border-radius: 2px;

        &:first-child {
          background-color: #146bd2;
        }

        span {
          &:first-child {
            font-size: 14px;
          }

          &:last-child {
            font-size: 28px;
            font-weight: bold;
          }
        }
      }
    }
  }

  // 图例
  .legend {
    position: absolute;
    left: 5px;
    bottom: 5px;
    width: 110px;
    background-color: #081631;

    p {
      text-align: center;
    }

    $colorlist: #b4002d, #fffc47, #fc6c22, #fc0011, #b5005e;

    @for $i from 1 to 6 {
      .item:nth-child(#{$i}) {
        margin: 10px 8px;

        span {
          &:first-child {
            display: inline-block;
            width: 20px;
            height: 10px;
            background-color: nth($colorlist, $i);
          }

          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }

  // 设备详情
  .marker-info {
    width: 250px;
    height: 200px;
    position: absolute;
    left: 2%;
    bottom: 2%;
    padding: 10px;
    background-color: rgba($color: #0c72f8, $alpha: 0.2);
    text-shadow: 1px 1px 0 $color_black;

    .info-title {
      font-weight: bold;
      color: #ffcc00;
    }

    span {
      margin-right: 8px;
      color: #ffcc00;
    }
  }

  .info-window {
    height: 120px;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    .left {
      width: 200px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;

      span {
        line-height: 25px;
      }
    }

    .right {
      flex: auto;
    }
  }

  // 视频dialog
  .dialog {
    position: absolute;
    width: 80%;
    min-width: 450px;
    height: 600px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: #ffffff;
  }

  // 重写百度地图infowindow样式

  // .BMap_pop div:nth-child(1) div,
  // .BMap_pop div:nth-child(3) div,
  // .BMap_pop div:nth-child(5) div,
  // .BMap_pop div:nth-child(7) div,
  // .BMap_pop .BMap_top,
  // .BMap_pop .BMap_center,
  // .BMap_pop .BMap_bottom {
  // 	background-color: rgba($color: #0C72F8, $alpha: 0.8) !important;
  // 	border: 0 !important;
  // }

  $color: rgba(
    $color: #081631,
    $alpha: 1,
  );

  .BMap_pop .BMap_top {
    background: $color !important;
    border: 0 !important;
  }

  .BMap_pop .BMap_center {
    border: 0 !important;
    background: $color !important;
  }

  .BMap_pop .BMap_bottom {
    border: 0 !important;
    background: $color !important;
  }

  .BMap_pop div:nth-child(3) {
    background: transparent !important;
  }

  .BMap_pop div:nth-child(3) div {
    background: $color !important;
    border: 0 !important;
  }

  .BMap_pop div:nth-child(1) {
    background: transparent !important;
    border: 0 !important;
  }

  .BMap_pop div:nth-child(1) div {
    background: $color !important;
  }

  .BMap_pop div:nth-child(5) {
    background: transparent !important;
    border: 0 !important;
  }

  .BMap_pop div:nth-child(5) div {
    background: $color !important;
  }

  .BMap_pop div:nth-child(7) {
    background: transparent !important;
    left: 226px;
  }

  .BMap_pop div:nth-child(7) div {
    background: $color !important;
  }

  img[src="https://api.map.baidu.com/images/iw_close1d3.gif"]
  {
    display: none;
  }

  /*替换关闭按钮*/
  img[src="http://api0.map.bdimg.com/images/iw_close1d3.gif"]
  {
    content: url("http://vino.test.bidostar.cn/iw_close1d3.png");
  }

  /*替换剪头*/
  img[src="http://api0.map.bdimg.com/images/iw3.png"]
  {
    opacity: 0.7;
    margin-top: -692px !important;
    filter: alpha(opacity=70);
    content: url("http://vino.test.bidostar.cn/iw3.png");
  }

  /*替换箭头*/
  img[src="http://api0.map.bdimg.com/images/iw3.png"]
  {
    display: none !important;
  }

  img[src="https://api.map.baidu.com/images/iw3.png"]
  {
    display: none !important;
  }
}
</style>
