<template>
  <!-- 布局 -->
  <div class="v-container">
    <!-- 左 -->
    <div class="v-left" style="display: none;">
    </div>
    <!-- 中 -->
    <div class="v-content">
      <div class="v-box" style="display: none;"></div>
      <!--地图-->
      <div class="v-box">
        <Map :deviceItems="deviceData" :mapData="mapdata" ref="mapMethod"></Map>
      </div>
      <div class="v-box" style="display: none;"></div>
    </div>
    <!-- 右 -->
    <div class="v-right">
      <!--内涝设备-->
      <div class="v-box">
        <div class="title">
          <span>内涝设备</span>
          <div class="choose"></div>
          <span class="dot">●</span>
        </div>
        <div ref="scroll" class="scroll">
          <div class="value">
            <div class="tb-div">
              <div class="tb-div-content">监测点名称</div>
              <div class="tb-div-content-center">水位值<span class="unit">(米)</span></div>
              <div class="tb-div-content-center">雨量<span class="unit">(毫米)</span></span></div>
              <div class="tb-div-content-center">信号</div>
              <div class="tb-div-content-center">采集时间</div>
            </div>
            <!--事件委托方式触发点击-->
            <div @click="listChange">
              <vue-seamless-scroll :data="deviceData" class="seamless-warp" ref="seamlessScroll" :class-option="seamlessOpt" :key="scrollKey">
                <!-- <ul> -->
                <li class="tb-div-li" v-for="(item,index) in deviceData" :key="index" :data-item="JSON.stringify(item)" :uid="item.uid">
                  <div class="deviceItems-td1">{{index + 1}}</div>
                  <div :style="{color:(item.alarm?'#F56C6C':'')}" class="tb-div-content-ui" :uid="item.uid" :title="item.title">{{item.title || item.uid}}</div>
                  <div :style="{color:(item.alarm?'#F56C6C':'')}" class="tb-div-content-ui" :uid="item.uid" :title="item.f">{{item.f}}</div>
                  <div :style="{color:(item.alarm?'#F56C6C':'')}" class="tb-div-content-ui" :uid="item.uid" :title="item.rainFall">{{item.rainFall}}</div>
                  <div :style="{color:(item.alarm?'#F56C6C':'')}" class="tb-div-content-ui" :uid="item.uid" :title="item.s">{{item.s}}</div>
                  <div :style="{color:(item.alarm?'#F56C6C':'')}" class="tb-div-content-ui" :uid="item.uid" :title="item.time" style="text-align: center;">{{item.time}}</div>
                </li>
                <!-- </ul> -->
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from '../VMapDayawan.vue'
export default {
  data() {
    return {
      timer: null,
      curTime: '',
      deviceData: [],
      hoverIndex: null, // 浮动索引
      scrollKey: 0,
      // mapdata: {
      //   center: {
      //     lng: 113.931809,
      //     lat: 22.579825,
      //   },
      //   zoom: 13
      // },  // 大亚湾小地图
      logo: {},
      alarmData: [],
      selectDevice: "",
    }
  },
  components: {
    Map,
  },
  props: {
    //设备数据
    mapdata: {},
  },
  watch: {

  },
  methods: {
    //获取全部设备
    dataRequest() {
      let json = {
        "cmd": "devices"
      }
      this.ws.send(json);
      this.ws.addCallback('devicesAck', (res) => {
        //过滤出内涝数据
        // console.log("设备数据：", res);
        this.deviceData = [] //有新数据时复位内涝数组
        res.devices.forEach(item => {
          if (item.model == 'dmWater') {
            this.deviceData.push(
              {
                'uid': item.uid,
                'title': item.title,
                'model': item.model,
                'alarm': 0,
              }
            )
          }
        })
        this.setHotDevices() //设置内涝设备为热点设备
        // this.setAlarmData()
      })

      this.getData()
    },
    //接收实时值
    getData(obj) {
      this.ws.addCallback('event', (res) => {
        // console.log("大屏接收res数据", res);

        if (res.type != "sensor") {
          return;
        } else {
          let uidData = this.getDevice(res.uid);
          let data;
          if (uidData != null) {
            data = this.parseProfile.parseData2(uidData.model, res.data ? res.data : "");
            let jsonData = JSON.parse(res.data);
            // console.log("大屏接收res数据", res.data);
            let deviceData = [...this.deviceData]
            deviceData.forEach((item, index) => {
              if (item.uid == res.uid && data.length > 0) {
                let f_key = data[0].findIndex(val => { return val.key === "f" })
                let s_key = data[0].findIndex(val => { return val.key === "s" })
                let time = res.dt.slice(6, 16)  //直接取返回的时间
                // let obj = this.deviceData[index]
                // obj = Object.assign({}, obj, {
                //   f: data[0] ? data[0][f_key].value : '',
                //   s: data[0] ? data[0][s_key].value : '',
                //   time: res.dt
                // })
                // console.log(res.dt, 999, this.deviceData[index].title)
                // this.deviceData.splice(index, 1, obj)
                this.$set(this.deviceData[index], 'f', data[0] ? data[0][f_key].value : '') //水位
                this.$set(this.deviceData[index], 'rainFall', jsonData.rainFall != undefined ? jsonData.rainFall : '')  //雨量
                this.$set(this.deviceData[index], 's', data[0] ? data[0][s_key].value : '') //信号
                this.$set(this.deviceData[index], 'time', time) //时间


              }
            })
          }
        }
      })
    },
    // 设置热点设备
    setHotDevices() {
      let uids = "";
      this.deviceData.forEach((item) => {
        uids += item.uid + ",";
      })
      let json = {
        "cmd": "setHotDevices",
        "uids": uids
      }
      this.ws.send(json);
    },

    //设置报警数据
    setAlarmData() {
      let alarmList = this.alarmData
      //内涝设备
      this.deviceData.forEach(item => {
        //列表是否有报警数据
        let data = alarmList.filter(e => { return item.uid == e.uid })
        if (data.length > 0) {
          item.alarm = 1
        } else {
          item.alarm = 0
        }
      })
    },
    getDevice(uid) {
      let obj = this.common.cFindObj(this.deviceData, "uid", uid);
      return obj;
    },
    //点击列表
    listChange(item) {
      // this.$refs.mapMethod.selectDevice = item.uid
      // console.log(111, item.target);
      let uid = item.target.getAttribute("uid")
      this.$refs.mapMethod.setMapDevice(uid)
    },

  },
  mounted() {
    this.alarmData = this.$store.state.alarmData //获取全局存储


    this.dataRequest();//请求所有设备数据
    this.ws.addCallback('jsonChannelReady', (res) => {
      // this.fetchDashboard();
    })




    this.timer = setInterval(() => {
      if (this.$refs.seamlessScroll) {  //避免页面不在大屏时获取不到滚动，报错
        // console.log('滚动刷新');
        this.$refs.seamlessScroll.reset();//触发子组件中的重置方法
      }
    }, 1000)

  },
  computed: {
    seamlessOpt() {   //滚动参数设置
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 20, // 开始无缝滚动的数据量 
        openWatch: true, // 开启数据实时监控刷新dom
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/iconfont/icon_dot.css";

$top-h: 65px;
$border_color: #077ccb;
$title_color: #0c72f8;
$boxborder_color: #02a6b5; //折角颜色
$boxborder_wh: 0.3rem; //边框折角长度
$boxback_color: #0c216b; //box框背景色
// 布局
.v-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - #{$top-h} - 20px);
  // background-color: #050c1c;
  margin: 10px;
  box-sizing: border-box;
  font-size: calc(100vw / 110);
  // 左
  .v-left {
    flex: 0;
    display: flex;
    flex-direction: column;

    .v-box {
      border: 1px solid rgba(25, 186, 139, 0.17);

      &:nth-child(1) {
        flex: 0.33;
      }
      &:nth-child(2) {
        flex: 0.34;
      }
      &:nth-child(3) {
        flex: 0.33;
        overflow: auto;
      }
    }
  }

  // 中
  .v-content {
    flex: 0.67;
    display: flex;
    flex-direction: column;
    .v-box {
      border: 1px solid rgba(25, 186, 139, 0.17);

      &:nth-child(1) {
        flex: 0;
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3) {
        flex: 0;
        overflow: auto;
      }
    }
  }

  // 右
  .v-right {
    flex: 0.33;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(25, 186, 139, 0.17);

    .scroll {
      .value {
        //标题
        .tb-div {
          height: 30px;
          font-size: 16px;
          display: inline-flex;
          width: 100%;
          .tb-div-content {
            width: 25%;
            text-align: center;
            padding-left: 20px;
            color: #6fb5ba;
            border: 1px solid #4d61a1;
            border-top-style: none;
            border-left-style: none;
            border-right-style: none;
          }
          .tb-div-content-center {
            width: 25%;
            text-align: center;
            color: #6fb5ba;
            border: 1px solid #4d61a1;
            border-top-style: none;
            border-left-style: none;
            border-right-style: none;
            .unit {
              font-size: 12px;
            }
          }
        }
        //列表
        .seamless-warp {
          overflow: hidden;
          height: calc((178vh - 120px) / 2); //84vh;182
          li {
            // margin: 15px 0;
            font-size: 18px;
            text-align: left;
            cursor: pointer;
            span {
              font-weight: normal;
              vertical-align: middle;
              color: #333;
            }

            .font-blue {
              color: #06f;
            }

            .font-white {
              color: white;
            }

            .date {
              float: right;
              color: #999;
            }

            &:first-child {
              margin-top: 0;
            }

            // img {
            //     width: 20px;
            //     margin-right:10px;
            //     vertical-align: middle;
            // }
          }
          .tb-div-li {
            border: 1px solid #4d61a1;
            border-top-style: none;
            border-left-style: none;
            border-right-style: none;
            height: 40px;
            font-size: 14px;
            display: inline-flex;
            width: 100%;
          }
          .tb-div-li:hover {
            background: #4d61a1;
          }
          .deviceItems-td1 {
            display: inline-block;
            width: 12px;
            height: 15px;
            // line-height: 14px;
            padding: 5px;
            margin-top: 11px;
            color: white;
          }
          .tb-div-content-ui {
            // border: 1px solid #4d61a1;
            // border-top-style: none;
            // border-left-style: none;
            // border-right-style: none;
            width: 25%;
            text-align: center;
            color: #dedede;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // padding: 3px;
            margin-top: 14px;
            margin-left: 2px;
          }
        }
      }
    }
  }

  // 自定义滚动条
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: darken($color: $border_color, $amount: 15%);
  }

  ::-webkit-scrollbar-track {
    border-radius: 12px;
  }
}

// 盒子
.v-box {
  $border_color2: #49c7d9;

  min-width: 280px;
  min-height: 100px;
  background-color: lighten($color: #050c1c, $amount: 2%);
  background-repeat: no-repeat;
  background-size: 2px 12px, 20px 2px;
  position: relative;
  margin: 3px;
  padding: 5px;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  // 标题
  .title {
    font-size: 15px;
    font-weight: bold;
    padding: 0 8px 10px 8px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .choose {
      flex: auto;
    }

    .dot {
      font-size: 16px;
      color: #0a87e9;
    }
  }

  // 数据
  .value {
    flex: auto;
  }

  table {
    width: 100%;
    height: 100%;
    color: #dedede;
    border-spacing: 0;

    tr th {
      color: #6fb5ba;
    }

    tr th,
    tr td {
      padding: 5px;
      text-align: center;
      border-bottom: 1px solid #4d61a1;
    }

    .data1-td1 {
      display: inline-block;
      width: 15px;
      line-height: 14px;
      padding-top: 2px;
    }
    .data11-td1 {
      display: inline-block;
      width: 15px;
      line-height: 14px;
      padding-top: 2px;
    }

    .data3-td1 {
      color: #1274e6;
    }
  }
}
</style>